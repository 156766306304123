import { useEffect, useMemo, useState, MouseEvent } from "react";
import SideBarList from "components/sidebar/SidebarData";
import { generateUserLayout } from "utils/roles_and_permission";
import { IUserDetails } from "api";
import { AUTHENTICATED_ROUTES, generateRoute } from "routes";
import { toggleNavbarModal, useAuthUser } from "store";
import { PAGES_WITHOUT_SIDEBARS } from "app/constants";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "hooks/useWindowSize";
import { useVAppDispatch, useVAppSelector } from "hooks/useStore";

export const useSideBarHook = () => {
  const [inMarketPlace, setInMarketPlace] = useState(false);
  const [isSideBarMinimzed, setIsSideBarMinimzed] = useState(false);
  const { SidebarData } = SideBarList();
  const { userData } = useAuthUser();
  const location = useLocation();
  const { width } = useWindowSize();
  const isMobile = (width as number) <= 762;

  const { open } = useVAppSelector((state) => state.navbar);
  const dispatch = useVAppDispatch();

  const { permissibleMenuList, permissibleRoutes } = useMemo(() => {
    return generateUserLayout({
      user: userData || ({} as IUserDetails),
      allRoutes: AUTHENTICATED_ROUTES,
      sideBarData: SidebarData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userData)]);

  const shouldHideSidebar = PAGES_WITHOUT_SIDEBARS.some((route) =>
    location.pathname.includes(route)
  );

  const closeMobileSidebar = (event: MouseEvent) => {
    if (event.target !== event.currentTarget) return;
    dispatch(toggleNavbarModal());
  };

  const routes = useMemo(() => {
    return generateRoute(permissibleRoutes);
  }, [permissibleRoutes]);

  useEffect(() => {
    if (inMarketPlace) {
      setIsSideBarMinimzed(true);
    }
  }, [inMarketPlace]);

  useEffect(() => {
    if (location.pathname === "/store/marketplace") {
      setInMarketPlace(true);
    } else {
      setInMarketPlace(false);
    }
  }, [location.pathname]);

  return {
    isSideBarMinimzed,
    permissibleMenuList,
    permissibleRoutes,
    shouldHideSidebar,
    routes,
    setIsSideBarMinimzed,
    inMarketPlace,
    isMobile,
    open,
    dispatch,
    closeMobileSidebar,
  };
};
