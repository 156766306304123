import { useAnalytics } from "hooks/useAnalytics";
import {
  constructOAuthRedirect,
  getEnvKey,
  redirectUrl,
} from "./../../utils/libs";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { AppStorage, makeId } from "utils";

const { addToStore } = new AppStorage();

type OAuthUrlProps = {
  url: string;
  nonce: string;
  state: string;
};

export const useOauthIframeHook = () => {
  const iframeRef = useRef(null);
  const [oauthData, setOAuthData] = useState<OAuthUrlProps>(
    {} as OAuthUrlProps
  );

  const [logAppEvent, { login: appLogin }] = useAnalytics();

  useEffect(() => {
    const nonce = makeId(10);
    const state = makeId(10);
    addToStore("nonce", nonce);
    const newUrl = getEnvKey("REACT_APP_OAUTHBASEURL");

    const oAuthBase = constructOAuthRedirect(newUrl, redirectUrl);
    const url = `${oAuthBase}&state=${state}&nonce=${nonce}`;

    setOAuthData({
      url,
      nonce,
      state,
    });
  }, []);

  const login = (url: string) => {
    logAppEvent(appLogin, {});
    window.open(url, "_self");
  };

  return {
    iframeRef,
    oauthData,
    login,
  };
};
